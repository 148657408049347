// src/TermsAndConditions.js
import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="t-c-pages">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Terms and Conditions</h1>

          <h2>1. Introduction</h2>
          <p>
            These Terms and Conditions govern the use of the services provided by Automate-Mailscale
            ("we", "our", or "us"). By accessing or using our services, you agree to be bound by
            these Terms and Conditions. Please read them carefully before using our services. If you
            do not agree with any part of these terms, you should refrain from using our services.
          </p>

          <h2>2. Governing Law</h2>
          <p>
            These terms and conditions are governed by the laws of the United Kingdom. Any disputes
            arising in connection with these terms shall be subject to the exclusive jurisdiction of
            the courts of the United Kingdom.
          </p>

          <h2>3. Data Handling and Privacy</h2>
          <h4>3.1 Data Collection</h4>
          <p>
            We do not store, share, or sell any of your data. Once you provide your email list for
            data cleaning, it is processed solely for the purpose of cleaning. After processing, the
            cleaned list is securely returned to you via email.
          </p>
          <h4>3.2 Data Retention</h4>
          <p>
            We do not retain any records of your data beyond the cleaning process. After delivery of
            the cleaned data, all copies are permanently deleted from our systems.
          </p>
          <h4>3.3 Data Security</h4>
          <p>
            We take reasonable steps to protect the data provided to us during the cleaning process.
            However, we cannot guarantee complete data security and are not liable for any
            unauthorized access or breach beyond our control.
          </p>

          <h2>4. Accuracy of Data Cleaning</h2>
          <p>
            The data cleaning process provides up to 97% accuracy at the time of scanning. However,
            due to the dynamic nature of email data and factors outside our control, we cannot
            guarantee the ongoing validity or accuracy of results. By using our service, you
            acknowledge that there are inherent risks, and we are not liable for any inaccuracies
            that may arise after the data has been processed.
          </p>

          <h2>5. Subscription and Access Duration</h2>
          <h4>5.1 Subscription</h4>
          <p>
            By subscribing to our services, you agree to pay the applicable fees for the selected
            subscription plan. The subscription grants you 1 year of access to our data cleaning
            services, beginning from the date of purchase.
          </p>
          <h4>5.2 Renewals</h4>
          <p>
            Upon the expiration of the 1-year subscription period, your subscription will not
            automatically renew. You will need to manually renew your subscription to continue using
            our services.
          </p>

          <h2>6. Service Availability and Liability</h2>
          <h4>6.1 Service Interruptions</h4>
          <p>
            While we strive to provide continuous and uninterrupted services, we cannot guarantee
            that our services will always be available. System failures, maintenance, or unforeseen
            technical issues may result in temporary downtime.
          </p>
          <h4>6.2 Limitation of Liability</h4>
          <p>
            We cannot be held liable for any losses, damages, or inconveniences caused by system
            failures or server downtimes. We will make every effort to resolve issues within 48
            hours of notification, but we cannot guarantee specific resolution times.
          </p>
          <h4>6.3 Force Majeure</h4>
          <p>
            We are not liable for delays or failures in performance caused by events beyond our
            reasonable control, including but not limited to acts of God, natural disasters, power
            outages, or other unforeseen circumstances.
          </p>

          <h2>7. Disclaimer of Warranties</h2>
          <p>
            Our services are provided "as is" without any warranties or guarantees of any kind. We
            do not warrant that the services will be error-free, secure, or uninterrupted. You
            acknowledge that the use of our services is at your own risk.
          </p>

          <h2>8. User Responsibilities</h2>
          <h4>8.1 Compliance</h4>
          <p>
            You are responsible for ensuring that your use of our services complies with all
            applicable laws and regulations, including those related to data privacy and email
            marketing.
          </p>
          <h4>8.2 Use of Cleaned Data</h4>
          <p>
            You are solely responsible for how you use the cleaned data. We are not liable for any
            misuse of the data or any consequences that arise from using it inappropriately.
          </p>

          <h2>9. Termination</h2>
          <p>
            We reserve the right to terminate your access to our services if we determine that you
            have violated these Terms and Conditions or if your actions harm our business or
            reputation.
          </p>

          <h2>10. Support and Contact Information</h2>
          <p>
            For any issues, concerns, or questions regarding our services, please email our support
            team at <a href="mailto:support@automate-mailscale.io">support@automate-mailscale.io</a>.
            We will make every effort to assist you and resolve any problems promptly.
          </p>

          <h2>11. Changes to Terms and Conditions</h2>
          <p>
            We reserve the right to modify or update these Terms and Conditions at any time without
            prior notice. Any changes will be posted on our website, and your continued use of our
            services constitutes acceptance of the revised terms.
          </p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default TermsAndConditions;
